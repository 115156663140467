import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default () => (
  <>
    <Seo title='Política de Cookies' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Política de Cookies"
          subheader='Nuestra poĺítica sobre el uso de las cookies'
        />
<div>
<h2>¿Qué son las cookies?</h2>

<p>Una cookie es un fichero que se descarga en su ordenador al acceder a
determinadas páginas web. Las cookies permiten a una página web, entre otras
cosas, almacenar y recuperar información sobre los hábitos de navegación de un
usuario o de su equipo y, dependiendo de la información que contengan y de la
forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>

<h2>¿Qué tipos de cookies existen?</h2>
<ul>
<li>
    Cookies de análisis - Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
</li>
<li>
    Cookies publicitarias - Son aquéllas que, bien tratadas por nosotros o por terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.
</li>
</ul>
<h2>¿Qué tipos de cookies utiliza esta página web?</h2>
<p> Sólo usamos las cookies de análisis y <strong>no publicitarias</strong>, pues no mostramos avisos publicitarios y no promocionamos productos.</p>


<h2>Cómo desactivar las Cookies</h2>

<p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>

<p>A continuación puede acceder a la configuración de los navegadores webs más frecuentes para aceptar, instalar o desactivar las cookies:</p>
<ul>
<li><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es" target="_blank">Firefox</a></li>
<li><a href="https://support.apple.com/es-es/HT201265" target="_blank">Safari</a></li>
<li><a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">Google Chrome</a></li>
</ul>

<h2>Cookies de Terceros</h2>

<p>Esta página web utiliza servicios de terceros para recopilar información con fines estadísticos y de uso de la web.</p>

<p>Usamos Google Analytics para ver las estadísticas y ésta es su <a href="https://policies.google.com/technologies/types?hl=es" target="_blank">política sobre las cookies</a></p>




</div>

      </Main>
      <Sidebar>

      </Sidebar>
    </Stack>
  </>
)
